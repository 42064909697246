document.addEventListener('DOMContentLoaded', function() {
    /**
     * Main popup
     */
    const mainPagePopup = document.getElementById('main-page-popup');
    const mainPagePopupClose = document.querySelector('.main-page-popup-close');

    if (mainPagePopup) {

        let scrolled = false;
        let popUpLastFocusedElement;

        const handleScroll = () => {
            if (!scrolled) {
                setTimeout(() => {
                    mainPagePopup.classList.add('open');
                    mainPagePopup.setAttribute('aria-hidden', 'false');
                    popUpLastFocusedElement = document.activeElement;
                    mainPagePopupClose.focus();

                }, 3000);
                scrolled = true;

                // Remove the scroll event listener after it has been triggered
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        mainPagePopupClose.addEventListener('click', () => {
            mainPagePopup.classList.remove('open');
            mainPagePopup.setAttribute('aria-hidden', 'true');
            popUpLastFocusedElement.focus();


        });

        mainPagePopup.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                mainPagePopup.setAttribute('aria-hidden', 'true');
                popUpLastFocusedElement.focus();
                mainPagePopup.classList.remove('open');
            }
        });


        // Trap focus within the modal
        mainPagePopup.addEventListener('keydown', (e) => {
            const focusableElements = mainPagePopup.querySelectorAll('a, button, textarea, input, select, [tabindex]:not([tabindex="-1"])');
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            if (e.key === 'Tab') {
                if (e.shiftKey) { // Shift + Tab
                    if (document.activeElement === firstElement) {
                        e.preventDefault();
                        lastElement.focus();
                    }
                } else { // Tab
                    if (document.activeElement === lastElement) {
                        e.preventDefault();
                        firstElement.focus();
                    }
                }
            }
        });
    }


      /**
     * Side Tab Contact Modal
     */

      const openModalButton = document.querySelector('.contact-side-tab-handle');
      const closeModalButton = document.querySelector('.contact-side-tab-close');
      const modal = document.getElementById('contactModal');
      let lastFocusedElement;
  
      if (modal) {
  
          openModalButton.addEventListener('click', () => {
              lastFocusedElement = document.activeElement;
              modal.setAttribute('aria-hidden', 'false');
              modal.focus();
              modal.classList.add('open');
          });
  
          closeModalButton.addEventListener('click', () => {
              modal.setAttribute('aria-hidden', 'true');
              lastFocusedElement.focus();
              modal.classList.remove('open');
          });
  
          modal.addEventListener('keydown', (e) => {
              if (e.key === 'Escape') {
                  modal.setAttribute('aria-hidden', 'true');
                  lastFocusedElement.focus();
                  modal.classList.remove('open');
              }
          });
  
          // Trap focus within the modal
          modal.addEventListener('keydown', (e) => {
              const focusableElements = modal.querySelectorAll('a, button, textarea, input, select, [tabindex]:not([tabindex="-1"])');
              const firstElement = focusableElements[0];
              const lastElement = focusableElements[focusableElements.length - 1];
  
              if (e.key === 'Tab') {
                  if (e.shiftKey) { // Shift + Tab
                      if (document.activeElement === firstElement) {
                          e.preventDefault();
                          lastElement.focus();
                      }
                  } else { // Tab
                      if (document.activeElement === lastElement) {
                          e.preventDefault();
                          firstElement.focus();
                      }
                  }
              }
          });
      }
  
});